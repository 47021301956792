import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { update } from '@app/adapter/user-service';
import { FileInfo } from '@app/components/Shared/UploadFile/Util';
import {
  afterLoginRouteSelector,
  loggedInUserState,
  userAuthInfoSelector,
} from '@app/domain/app';
import {
  errorSnackbarOpenState,
  errorSnackbarSeverityState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { userUpdateState } from '@app/domain/user';
import { urlToBase64 } from '@app/utils/base64';

export function EditConfirm(): ReactElement {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userAuthInfoSelector);
  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserState);
  const [updateSharedState, setUpdateSharedState] =
    useRecoilState(userUpdateState);
  const [afterLoginRoute, setAfterLoginRoute] = useRecoilState(
    afterLoginRouteSelector
  );
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setErrorSnackbarSeverity = useSetRecoilState(
    errorSnackbarSeverityState
  );

  const handleClickUpdate = async () => {
    if (!userInfo || !loggedInUser) {
      navigate('/login');
      return;
    }

    try {
      const payload = { ...updateSharedState };

      const orderAfterFiles: FileInfo[] = [];
      payload.customFields.orderAfterFiles?.forEach(async (file) => {
        orderAfterFiles.push({
          ...file,
          fileUrl: await urlToBase64(file.fileUrl),
        });
      });
      const orderMatchedFiles: FileInfo[] = [];
      payload.customFields.orderMatchedFiles?.forEach(async (file) => {
        orderMatchedFiles.push({
          ...file,
          fileUrl: await urlToBase64(file.fileUrl),
        });
      });
      payload.customFields = {
        ...payload.customFields,
      };

      await update(
        userInfo.userId,
        userInfo.accessToken,
        userInfo.fingerPrint,
        payload
      );
      setLoggedInUser({ ...loggedInUser, ...payload });
      setUpdateSharedState(payload);

      setErrorSnackbarText('登録しました');
      setErrorSnackbarSeverity('success');
      setErrorSnackbarOpen(true);
      if (afterLoginRoute) {
        navigate(afterLoginRoute);
        setAfterLoginRoute(null);
      } else {
        navigate('/home');
      }
    } catch (err) {
      setErrorSnackbarText('登録に失敗しました');
      setErrorSnackbarOpen(true);
    }
  };

  return (
    <Container fixed>
      <Typography
        variant="h5"
        fontWeight={700}
        textAlign="center"
        sx={{ my: 4 }}
      >
        インフルエンサー情報登録
      </Typography>
      <Typography mb={3}>
        入力内容を確認の上、送信ボタンを押してください。
      </Typography>
      <Stack spacing={3}>
        <Box>
          <Typography variant="body2">姓名</Typography>
          <Typography>
            {`${updateSharedState.customFields.familyName}${updateSharedState.customFields.firstName}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">姓（かな）名（かな）</Typography>
          <Typography>
            {`${updateSharedState.customFields.familyNameKana}${updateSharedState.customFields.firstNameKana}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">性別</Typography>
          <Typography>{updateSharedState.customFields.gender}</Typography>
        </Box>
        <Box>
          <Typography variant="body2">自認の性別</Typography>
          <Typography>{updateSharedState.customFields.genderSelf}</Typography>
        </Box>
        <Box>
          <Typography variant="body2">生年月日</Typography>
          <Typography>
            {updateSharedState.customFields.birthday
              ? format(
                  new Date(updateSharedState.customFields.birthday),
                  'yyyy年MM月dd日'
                )
              : ''}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">連絡先電話番号</Typography>
          <Typography>{updateSharedState.phoneNumber}</Typography>
        </Box>
        <Box>
          <Typography variant="body2">SNS名</Typography>
          <Typography>
            {updateSharedState.customFields.socialMediaName}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">利用SNS</Typography>
          <Typography>
            {updateSharedState.customFields.usedSocialMediaPlatforms}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">利用SNSのURL</Typography>
          <Typography>
            {updateSharedState.customFields.socialMediaUrl}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">特記事項</Typography>
          <Typography>{updateSharedState.customFields.notes}</Typography>
        </Box>

        {/* TODO:ファイルアップロード処理を理解してから実装する
        <Box>
          <Typography variant="body2">予約後開示_添付資料データ</Typography>
          <Typography>推奨の添付資料： 履歴書、職務経歴書、他</Typography>
          <Stack spacing={1}>
            {updateSharedState.customFields.orderAfterFiles?.map(
              (file, index) => (
                <Stack key={index} direction="row">
                  <Box width={1}>
                    <Typography component="span">{file.name}</Typography>
                  </Box>
                  <Link href={file.fileUrl} target="_blank" whiteSpace="nowrap">
                    表示する
                  </Link>
                </Stack>
              )
            )}
          </Stack>
        </Box>
        <Box>
          <Typography variant="body2">採用決定後開示_添付資料データ</Typography>
          <Typography>推奨の添付資料： 免許、保険医登録票、他</Typography>
          <Stack spacing={1}>
            {updateSharedState.customFields.orderMatchedFiles?.map(
              (file, index) => (
                <Stack key={index} direction="row">
                  <Box width={1}>
                    <Typography component="span">{file.name}</Typography>
                  </Box>
                  <Link href={file.fileUrl} target="_blank" whiteSpace="nowrap">
                    表示する
                  </Link>
                </Stack>
              )
            )}
          </Stack>
        </Box>
        */}
        <Stack spacing={2}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleClickUpdate}
          >
            登録
          </Button>
          <Button
            color="inherit"
            variant="outlined"
            fullWidth
            onClick={() => navigate('/profile/edit')}
          >
            入力画面に戻る
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
