import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
  Select,
  Stack,
  MenuItem,
  ListItemText,
} from '@mui/material';
import _ from 'lodash';
import { ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { resetResponseInterceptor } from '@app/adapter/axios';
import { resendVerificationEmail } from '@app/adapter/user-service';
import {
  useClearAuthStateAndStorage,
  loggedInUserState,
  userAuthInfoSelector,
} from '@app/domain/app';
import { userUpdateState } from '@app/domain/user';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { UserUpdateForm } from '@app/schemas/user';
import { theme } from '@app/theme';
import {
  SocialPlatforms,
  UserCustomFields,
  UserUpdate,
  socialMediaDomains,
} from '@app/types/user';
import { socialMediaOptions } from '@app/utils/constants';
import { isValidUrlFormat } from '@app/utils/user';

const validateSocialMediaUrls = (
  urls: string[],
  selectedPlatforms: SocialPlatforms[]
) => {
  if (!selectedPlatforms || selectedPlatforms.length === 0) {
    return '利用SNSを選択してください。';
  }

  const validDomains: string[] = selectedPlatforms.reduce((acc, platform) => {
    const domains = socialMediaDomains[platform] || [];
    return acc.concat(domains);
  }, [] as string[]);

  const isAnyDomainPresent = selectedPlatforms.every((platform) => {
    const domains = socialMediaDomains[platform] || [];
    return domains.some((domain) =>
      urls.some((url) => isValidUrlFormat(url, domain))
    );
  });

  if (!isAnyDomainPresent) {
    return '入力されたURLに選択したSNSのドメインがありません、または形式が正しくありません。';
  }

  const isAllValid = urls.every((url) =>
    validDomains.some((domain) => isValidUrlFormat(url, domain))
  );

  return isAllValid
    ? true
    : '入力されたURLが選択したSNSのドメインと一致しないか、形式が正しくありません。';
};

const genderOptions = ['男性', '女性'] as const;
const genderSelfOptions = [...genderOptions, 'その他', '無回答'] as const;

const yearOptions = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 101 }, (i, index) => currentYear - index);
};

export function Edit(): ReactElement {
  const navigate = useNavigate();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const userInfo = useRecoilValue(userAuthInfoSelector);
  const setSnackbar = useSetSnackbar();
  const [updateSharedState, setUpdateSharedState] =
    useRecoilState(userUpdateState);
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { isValid },
  } = useForm<UserUpdate>({
    defaultValues: UserUpdateForm.defaultValues,
    mode: 'onChange',
    resolver: UserUpdateForm.resolver,
  });

  // 初期値をセットする
  useEffect(() => {
    // キャッシュ値にある入力情報もしくは登録情報を参照する
    const userValues = updateSharedState.customFields?.familyName
      ? updateSharedState
      : loggedInUser;
    const defaultValues = UserUpdateForm.defaultValues;
    // プロパティ単位で値をセットする
    (Object.keys(defaultValues) as (keyof UserUpdate)[]).forEach((key) => {
      // キャッシュ値がなければデフォルト値を参照する
      const value = _.get(userValues, key) || defaultValues[key];
      if (key === 'customFields') {
        // カスタムフィールドの場合
        (
          Object.keys(defaultValues.customFields) as (keyof UserCustomFields)[]
        ).forEach((customKey) => {
          // キャッシュ値がなければデフォルト値を参照する
          const cValue =
            _.get(value, customKey) || defaultValues.customFields[customKey];
          setValue(`${key}.${customKey}`, cValue);
        });
      } else if (typeof value !== 'object') {
        setValue(key, value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSocialPlatformKey = (value: string): SocialPlatforms | undefined => {
    const entries = Object.entries(SocialPlatforms).find(
      ([, val]) => val === value
    );
    return entries
      ? SocialPlatforms[entries[0] as keyof typeof SocialPlatforms]
      : undefined;
  };

  const onSubmit = (data: UserUpdate) => {
    const urls = data.customFields.socialMediaUrl || [];
    const selectedPlatforms = data.customFields.usedSocialMediaPlatforms || [];

    const selectedPlatformsEnum = selectedPlatforms
      .map((platform) => getSocialPlatformKey(platform))
      .filter((x): x is SocialPlatforms => x !== undefined);

    const validationMessage = validateSocialMediaUrls(
      urls,
      selectedPlatformsEnum
    );
    if (validationMessage !== true) {
      setError('customFields.socialMediaUrl', {
        message: validationMessage as string,
        type: 'domainMatch',
      });
      return;
    }

    setUpdateSharedState(data);
    navigate('/profile/edit-confirm');
  };

  const formLabel = (label: string, require?: boolean): ReactElement => {
    return (
      <FormLabel>
        <Typography variant="body2">
          {label}
          {require && (
            <Typography component="span" color="error">
              *
            </Typography>
          )}
        </Typography>
      </FormLabel>
    );
  };

  // 会員登録しない場合はlogout状態にする
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();
  const handleClickSkipRegistration = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    clearAuthStateAndStorage();
    resetResponseInterceptor();
    navigate('/home');
  };

  const handleResendVerificationEmail = () => {
    if (!userInfo?.userId || !userInfo?.accessToken) return;

    resendVerificationEmail(
      userInfo.userId,
      userInfo.accessToken,
      userInfo.fingerPrint
    )
      .then(() => {
        setSnackbar(true, '認証メールを再送信しました。', 'success');
      })
      .catch((error) => {
        setSnackbar(true, 'メールの再送信に失敗しました。', 'error');
      });
  };

  return (
    <Container fixed>
      {!loggedInUser && (
        <Typography variant="body2" textAlign="center" mt={2}>
          会員登録せずに求人を確認したい方は
          <a href="/" onClick={handleClickSkipRegistration}>
            こちら
          </a>
          へ
        </Typography>
      )}
      <Typography
        variant="h5"
        fontWeight={700}
        textAlign="center"
        sx={{ my: 4 }}
      >
        インフルエンサー情報登録
      </Typography>
      {loggedInUser?.emailVerified === false && (
        <>
          <Typography
            variant="body1"
            textAlign="center"
            color={theme.palette.warning.main}
            sx={{ my: 3 }}
            fontWeight={'bold'}
          >
            メール認証が完了していないようです。
            下記ボタンをクリックして、認証メールを再度送信することが出来ます。
          </Typography>
          <Button
            variant="outlined"
            fullWidth
            onClick={handleResendVerificationEmail}
            sx={{
              '&:hover': {
                backgroundColor: '#f5f5f5',
                borderColor: theme.palette.warning.dark,
              },
              backgroundColor: '#ffffff',
              borderColor: theme.palette.warning.main,
              color: theme.palette.warning.main,
              mb: 4,
            }}
          >
            認証メールを再送信する
          </Button>
        </>
      )}
      <Box mb={3}>
        インフルエンサー情報をご記入のうえ送信してください。
        <Box>
          <Typography component="span" color="error">
            *
          </Typography>
          は必須事項です
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Box width={1}>
                {formLabel('姓', true)}
                <Controller
                  name="customFields.familyName"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'姓を入力'}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box width={1}>
                {formLabel('名', true)}
                <Controller
                  name="customFields.firstName"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'名を入力'}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Box width={1}>
                {formLabel('姓（かな）', true)}
                <Controller
                  name="customFields.familyNameKana"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'姓（かな）を入力'}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box width={1}>
                {formLabel('名（かな）', true)}
                <Controller
                  name="customFields.firstNameKana"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'名（かな）を入力'}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {formLabel('性別', true)}
            <Controller
              name="customFields.gender"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <Select {...field} margin="dense" error={!!error}>
                    {genderOptions.map((value, index) => (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!error && <FormHelperText>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {formLabel('自認の性別')}
            <Controller
              name="customFields.genderSelf"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <Select {...field} margin="dense" error={!!error}>
                    {genderSelfOptions.map((value, index) => (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!error && <FormHelperText>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {formLabel('生年月日', true)}
            <Stack direction="row" spacing={1} alignItems="center">
              <Controller
                name="customFields.birthday"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    value={
                      field.value
                        ? String(new Date(field.value).getFullYear())
                        : ''
                    }
                    margin="dense"
                    error={!!error}
                    fullWidth
                    onChange={(e) => {
                      if (field.value) {
                        const birthday = new Date(field.value);
                        birthday.setFullYear(Number(e.target.value));
                        field.onChange(birthday.toISOString());
                      }
                    }}
                  >
                    {yearOptions().map((value, index) => (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <Typography>年</Typography>
              <Controller
                name="customFields.birthday"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    value={
                      field.value
                        ? String(new Date(field.value).getMonth())
                        : ''
                    }
                    margin="dense"
                    error={!!error}
                    fullWidth
                    onChange={(e) => {
                      if (field.value) {
                        const birthday = new Date(field.value);
                        birthday.setMonth(Number(e.target.value));
                        field.onChange(birthday.toISOString());
                      }
                    }}
                  >
                    {Array.from({ length: 12 }).map((i, index) => (
                      <MenuItem key={index} value={index}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <Typography>月</Typography>
              <Controller
                name="customFields.birthday"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    value={
                      field.value ? String(new Date(field.value).getDate()) : ''
                    }
                    margin="dense"
                    error={!!error}
                    fullWidth
                    onChange={(e) => {
                      if (field.value) {
                        const birthday = new Date(field.value);
                        birthday.setDate(Number(e.target.value));
                        field.onChange(birthday.toISOString());
                      }
                    }}
                  >
                    {Array.from({ length: 31 }).map((i, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <Typography>日</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {formLabel('連絡先電話番号', true)}
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="tel"
                  error={!!error}
                  helperText={error?.message}
                  margin="dense"
                  placeholder="連絡先電話番号（ハイフンなし）で入力"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            {formLabel('SNS名', true)}
            <Controller
              name="customFields.socialMediaName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  margin="dense"
                  placeholder="SNS名を入力"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            {formLabel('利用SNS', true)}
            <Controller
              name="customFields.usedSocialMediaPlatforms"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth>
                  <Select
                    {...field}
                    labelId="social-media-select-label"
                    multiple
                    value={field.value ?? []}
                    renderValue={(selected) => selected.join(', ')}
                    margin="dense"
                    error={!!error}
                  >
                    {socialMediaOptions.map((value, index) => (
                      <MenuItem key={index} value={value}>
                        <Checkbox checked={field.value?.includes(value)} />{' '}
                        <ListItemText primary={value} />
                      </MenuItem>
                    ))}
                  </Select>
                  {!!error && <FormHelperText>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            {formLabel('利用SNSのURL', true)}
            <Controller
              name="customFields.socialMediaUrl"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  value={(field.value && field.value.join(', ')) || ''}
                  onChange={(e) => {
                    // 入力された値をカンマで分割し配列に変換
                    const urls = e.target.value
                      .split(',')
                      .map((url) => url.trim());
                    field.onChange(urls);
                  }}
                  onBlur={(e) => {
                    // 空白のみを削除し、カンマで分割して配列に変換
                    const cleanedUrls = e.target.value
                      .split(',')
                      .map((url) => url.trim())
                      .filter((url) => url);
                    field.onChange(cleanedUrls);
                    field.onBlur();
                  }}
                  error={!!error}
                  helperText={
                    error?.message || 'URLをカンマ区切りで入力してください。'
                  }
                  margin="dense"
                  placeholder="例：https://example.com, https://example.org"
                  fullWidth
                />
              )}
            />
            <Typography
              sx={{
                color: theme.palette.error.main,
                fontSize: '0.875rem',
                pl: 2,
              }}
            >
              ※ご自身のプロフィールページのURLをご登録ください
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {formLabel('特記事項')}
            <Controller
              name="customFields.notes"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  margin="dense"
                  rows={2}
                  placeholder="特記事項を入力"
                  multiline
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              disabled={!isValid}
            >
              送信
            </Button>
          </Grid>
        </Grid>
      </form>
      {!loggedInUser && (
        <Typography variant="body2" textAlign="center" mt={3}>
          会員登録せずに求人を確認したい方は
          <a href="/" onClick={handleClickSkipRegistration}>
            こちら
          </a>
          へ
        </Typography>
      )}
    </Container>
  );
}
