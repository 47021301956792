import { uniqueId } from 'lodash';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ChatDetail } from './routes/Chat/ChatDetail';
import { Index as Chat } from './routes/Chat/index';
import { LineCallback } from './routes/LineCallback';
import { LoginCallback } from './routes/LoginCallback';
import { NotificationDetail } from './routes/NotificationDetail';
import { OrderDetail } from './routes/OrderDetail';
import { Orders } from './routes/Orders';
import { PrivacyPolicyPage } from './routes/PrivacyPolicyPage';
import { TermsPage } from './routes/TermsPage';
import { WaitingForResetPassword } from './routes/WaitingForResetPassword';

import { DefaultLayout } from '@app/components/Layout/DefaultLayout';
import { ScrollToTop } from '@app/components/Shared/ScrollToTop';
import { Profile } from '@app/components/User/Profile';
import { ErrorFound } from '@app/routes/Error';
import { Home } from '@app/routes/Home';
import { Login } from '@app/routes/Login';
import { MockLine } from '@app/routes/MockLine';
import { NotFound } from '@app/routes/NotFound';
import { ProductDetail } from '@app/routes/ProductDetail';
import { ProtectedRoute } from '@app/routes/ProtectedRoute';
import { Register } from '@app/routes/Register';
import { SearchResult } from '@app/routes/SearchResult';
import { SendResetPasswordEmail } from '@app/routes/SendResetPasswordEmail';
import { Edit as UserEdit } from '@app/routes/User/Edit';
import { EditConfirm as UserEditConfirm } from '@app/routes/User/EditConfirm';
import { WaitingForEmailVerify } from '@app/routes/WaitingForEmailVerify';

export function App() {
  return (
    <Router>
      <ScrollToTop />
      <DefaultLayout>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login-callback/:provider" element={<LoginCallback />} />
          <Route
            path="/send_reset_password_email"
            element={<SendResetPasswordEmail />}
          />
          <Route
            path="/waiting_reset_password"
            element={<WaitingForResetPassword />}
          />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/profile/edit"
            element={
              <ProtectedRoute>
                <UserEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/edit-confirm"
            element={
              <ProtectedRoute>
                <UserEditConfirm />
              </ProtectedRoute>
            }
          />
          <Route path="/home" element={<Home />} />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute>
                <NotificationDetail />
              </ProtectedRoute>
            }
          />
          <Route path="/search/services" element={<SearchResult />} />
          <Route
            path="/favorite/services"
            element={
              <ProtectedRoute>
                <SearchResult favoriteSearch={true} key={uniqueId()} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/waiting-email-verify"
            element={<WaitingForEmailVerify />}
          />
          <Route path="/products/:productId" element={<ProductDetail />} />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:orderId"
            element={
              <ProtectedRoute>
                <OrderDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chats"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chats/:topicId"
            element={
              <ProtectedRoute>
                <ChatDetail />
              </ProtectedRoute>
            }
          />
          {/* NOTE: Temporarily added */}
          <Route path="/mock-line" element={<MockLine />} />
          <Route path="/line/callback" element={<LineCallback />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/error" element={<ErrorFound />} />
        </Routes>
      </DefaultLayout>
    </Router>
  );
}
