import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { generatePasswordPattern } from '@app/utils/pattern';
export interface IRegistrationForm {
  customFields: {
    familyName: string;
    firstName: string;
    socialMediaName: string;
    usedSocialMediaPlatforms: string[];
  };
  email: string;
  passwords: {
    confirmPassword: string;
    password: string;
  };
  phoneNumber: string;
  privacyPolicy: boolean;
  userAgreement: boolean;
}

const RegistrationFormDefaultValues = {
  customFields: {
    familyName: '',
    firstName: '',
    socialMediaName: '',
    usedSocialMediaPlatforms: [],
  },
  email: '',
  passwords: {
    confirmPassword: '',
    password: '',
  },
  phoneNumber: '',
  privacyPolicy: false,
  userAgreement: false,
};

const RegistrationFormSchema = z.object({
  customFields: z.object({
    familyName: z.string().min(1, { message: '姓を入力してください' }),
    firstName: z.string().min(1, { message: '名を入力してください' }),
    socialMediaName: z.string().min(1, { message: 'SNS名を入力してください' }),
    usedSocialMediaPlatforms: z
      .array(z.string())
      .nonempty('利用SNSを入力してください'),
  }),
  email: z.string().email({ message: 'メールアドレスを入力してください' }),
  passwords: z
    .object({
      confirmPassword: z
        .string()
        .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
        .max(24, '24文字以下のパスワードを入力してください')
        .regex(generatePasswordPattern(), {
          message:
            '少なくとも1文字以上の小文字英字、数字が必要です。許可されている文字は、半角英数字です',
        }),
      password: z
        .string()
        .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
        .max(24, '24文字以下のパスワードを入力してください')
        .regex(generatePasswordPattern(), {
          message:
            '少なくとも1文字以上の小文字英字、数字が必要です。許可されている文字は、半角英数字です',
        }),
    })
    .refine(({ password, confirmPassword }) => password === confirmPassword, {
      message: 'パスワードが一致しません',
      path: ['confirmPassword'],
    }),
  phoneNumber: z
    .string()
    .min(1, { message: '連絡先電話番号を入力してください' })
    .regex(/^0[0-9]{9,12}$/, {
      message: '連絡先電話番号はハイフンなしの数字を入力してください',
    }),
  privacyPolicy: z.literal(true, {
    errorMap: () => ({
      message: 'プライバシーポリシーに同意する必要があります',
    }),
  }),
  userAgreement: z.literal(true, {
    errorMap: () => ({ message: '利用規約に同意する必要があります' }),
  }),
});

export const RegistrationForm = {
  defaultValues: RegistrationFormDefaultValues,
  resolver: zodResolver(RegistrationFormSchema),
  schema: RegistrationFormSchema,
} as const;
