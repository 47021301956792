import { FileInfo } from '@app/components/Shared/UploadFile/Util';

export const TypeId = {
  DEMAND: '000',
} as const;
export type TypeIdKey = (typeof TypeId)[keyof typeof TypeId];

export interface User {
  avatar: string;
  customFields: UserCustomFields;
  email: string;
  emailVerified: boolean;
  id: string;
  name: string;
  password: string;
  phoneNumber: string;
  typeId: TypeIdKey;
}

export interface UserCustomFields {
  birthday?: string;
  familyName?: string;
  familyNameKana?: string;
  firstName?: string;
  firstNameKana?: string;
  gender?: string;
  genderSelf?: string;
  notes?: string;
  orderAfterFiles?: FileInfo[];
  orderMatchedFiles?: FileInfo[];
  socialMediaName?: string;
  socialMediaUrl?: string[];
  usedSocialMediaPlatforms?: string[];
}

export interface UserUpdate {
  customFields: UserCustomFields;
  phoneNumber: string;
}

export interface SocialMediaDomains {
  Facebook: string[];
  Google: string[];
  Instagram: string[];
  TikTok: string[];
  'X（旧Twitter）': string[];
}

export enum SocialPlatforms {
  Facebook = 'Facebook',
  Google = 'Google',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  Twitter = 'X（旧Twitter）',
}

export const socialMediaDomains: SocialMediaDomains = {
  Facebook: ['facebook.com'],
  Google: ['g.co', 'google.com', 'maps.app.goo.gl', 'maps.app'],
  Instagram: ['instagram.com'],
  TikTok: ['tiktok.com'],
  'X（旧Twitter）': ['twitter.com', 'x.com'],
};
